<template>
  <div class="page page__content-wrapper">
    <div class="page__full-width office-block office-top">
      <div class="page__min-container">
        <div class="office-block__wrapper">
          <div class="office-block__text">
            <div class="text__head">
              Визитные карточки
            </div>
            <div class="text__normal">
              Бумага: мелованный картон, 270–300 г/м<sup>2</sup> или дизайнерская бумага с&nbsp;текстурой. Допускается выборочная
              заливка глянцевым UV-лаком.
            </div>
            <div class="office-block__buttons">
              <ButtonToggle :is-active="buttonActive === 'ru'" @click="activatedRu">RU</ButtonToggle>
              <ButtonToggle :is-active="buttonActive === 'en'" @click="activatedEn">EN</ButtonToggle>
            </div>
          </div>
          <div class="office-block__image">
            <img
              :src="require(`@/assets/img/office-identity-page/office-cards-${buttonActive}.jpg`)"
              alt="office-cards"
            >
          </div>
        </div>
      </div>
      <div class="page__link-block download-block">
        <IconDownload
          v-for="(item, i) in iconsTop"
          :key="i"
          :url="item.url"
          :icon="item.icon"

        />
      </div>
    </div>

    <page-item v-for="(item, idx) in officeItems" :key="idx" :content="item"></page-item>

    <ButtonDownload
      text-button="Скачать все макеты"
      url="../download-file/06-Office/Steor-Office.zip"
      class="page__download-block"
    />

    <div class="page__full-width office-block office-bottom">
      <div class="page__min-container">
        <div class="text__head">
          Wallpapers
        </div>
        <div class="office-slider__wrapper">
          <img src="@/assets/img/office-identity-page/slides/office-layout.png" alt="layout">
          <div class="office-slider">
            <OfficeSlider :slides="slides"/>
          </div>
        </div>
      </div>
      <div class="page__link-block download-block">
        <IconDownload
          v-for="(item, i) in iconsBottom"
          :key="i"
          :url="item.url"
          :icon="item.icon"
          class-name="btn__nav-slider"
        />
      </div>
    </div>


    <BottomNavBlock :prev-link="bottomNav.pre" :next-link="bottomNav.next"/>
  </div>
</template>

<script>
import IconDownload from "@/components/Elements/IconDownload/IconDownload"
import BottomNavBlock from "@/components/Elements/BottomNavBlock/BottomNavBlock"
import ButtonDownload from "@/components/Elements/ButtonDownload/ButtonDownload"
import ButtonToggle from "@/components/Elements/ButtonToggle/ButtonToggle"
import OfficeSlider from "@/components/Sliders/OfficeSlider"
import PageItem from "../../../components/Elements/PageItem/PageItem"

export default {
  components: {
    PageItem,
    OfficeSlider,
    ButtonDownload,
    ButtonToggle,
    BottomNavBlock,
    IconDownload
  },

  data() {
    return {
      bottomNav: {
        pre: {
          url: "/identity/communication-rules",
          text: "Правила коммуникаций"
        },
        next: {
          url: "/brand/presentations",
          text: "Презентации"
        }
      },
      iconsTop: [
        {
          url: "../download-file/06-Office/Steor-Cards.zip",
          icon: "icon-ai.svg"
        }
      ],
      iconsBottom: [
        {
          url: "../download-file/06-Office/Steor-Screensaver.zip",
          icon: "icon-zip.svg"
        }
      ],
      buttonActive: "ru",
      officeItems: [
        {
          type: "image",
          title: "Бланк А4, ручка",
          images: [
            {
              img: "office-identity-page/office-blank.jpg",
              icons: [
                {
                  url: "../download-file/06-Office/Steor-Blank+Pen.zip",
                  icon: "icon-zip.svg"
                },
                {
                  url: "../download-file/06-Office/Steor-Blank+Pen-AI.zip",
                  icon: "icon-ai.svg"
                },
                {
                  url: "../download-file/06-Office/Steor-Blank-DOCX.zip",
                  icon: "icon-doc.svg"
                }
              ]
            }
          ]
        },
        {
          type: "image",
          title: "Бейдж и лента",
          images: [
            {
              img: "office-identity-page/office-badge.jpg",
              icons: [
                {
                  url: "../download-file/06-Office/Steor-Badge.zip",
                  icon: "icon-ai.svg"
                }
              ]
            }
          ],
        }
      ],
      slides: [
        {
          imgSlide: 'office-identity-page/slides/office-screen-01.jpg',
          isChecked: true
        },
        {
          imgSlide: 'office-identity-page/slides/office-screen-02.jpg',
          isChecked: false
        },
        {
          imgSlide: 'office-identity-page/slides/office-screen-03.jpg',
          isChecked: false
        },
        {
          imgSlide: 'office-identity-page/slides/office-screen-04.jpg',
          isChecked: false
        }
      ],
    }
  },
  methods: {
    activatedRu() {
      this.buttonActive = "ru"
    },
    activatedEn() {
      this.buttonActive = "en"
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/var-style";
@import "../../../assets/style/text-style";
@import "../../../assets/style/page-block";
@import "../../../assets/style/buttons";

.page {
  margin-top: 96px;
  margin-bottom: 296px;
  &__link-block {
    display: flex;
    //margin-left: 22px;
  }
  &__min-container {
    margin-bottom: 0;
  }
  &__download-block {
    margin-top: -1px;
    margin-bottom: 96px;
  }
}

.office-block {
  display: flex;
  align-items: center;

  &__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__text {
    width: 256px;
    min-width: 256px;
    margin-right: 32px;
  }

  &__image {
    img {
      width: 100%;
    }
  }

  &__buttons {
    display: flex;
    margin-top: 66px;
    & button {
      &:first-child {
        margin-right: 15px;
      }
    }
  }
}

.office-item {
  margin-bottom: -1px;
  .page__link-block {
    margin-top: -25px;
  }
}

.office-top {
  margin-bottom: 94px;
}

.office-bottom {
  .page__link-block {
    margin-top: -112px;
  }
}

.text {
  &__head {
    font-size: 26px;
    letter-spacing: 0;
    margin-bottom: 32px;
  }

  &__normal {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0;
  }
}

//.icon-download {
//  margin: 0 15px;
//}

.office-slider {
  position: absolute;
  top: 8px;
  &__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    img {
      width: 100%;
    }
  }
  &__title {

  }
}

///deep/.page-item {
//
//  .download-block {
//    margin-left: 37px;
//    .btn__nav-slider {
//      margin-right: 32px;
//      margin-left: 0;
//
//      &:last-child {
//        margin-right: 0;
//      }
//    }
//  }
//}


@media screen and (max-width: 1540px) {

  .page__content-wrapper {
    padding-left: 64px;
  }
}

@media screen and (max-width: 1456px) {
  .page {
    margin-bottom: 264px;
  }
}


@media screen and (max-width: 1320px) {

  .page__content-wrapper {
    padding-left: 0;
  }
}

@media screen and (max-width: 1266px) {
  .page__link-block.download-block {
    margin-left: -4px;
  }
}

@media screen and (max-width: 1216px) {
  .page__logo-slider-wrapper,
  .page__full-width.logo-top-slider {
    width: 100%;
  }
  .page__link-block {
    display: none;
  }
  .page__link-block.download-block {
    display: none;
  }
  .logo-top-slider {
    max-width: 832px;
    margin-right: auto;
    margin-left: auto;
  }
  .logo-page__min-slider {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .img-wrapper-full {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 1090px) {
  .page {
    margin-top: 0;
    padding-top: 50px;

    &__download-block {
      margin-bottom: 50px;
    }
  }
  .office-block {
    &__wrapper {
      position: relative;
      flex-direction: column;
      align-items: center;
    }
    
    &__text {
      width: 100%;
    }

    &__buttons {
      position: absolute;
      bottom: -40px;
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }


  .office-slider {
    top: -32px;

    &__wrapper {
      img {
        max-width: 694px;
      }
    }
  }
}

@media screen and (max-width: 896px) {
  .pages-wrap {
    .container {
      .page {
        &.page__content-wrapper {
          padding-left: 16px;
          padding-right: 16px;
        }
      }
    }
  }

  .page {
    &__full-width {
      width: 100%;
    }

    &__min-container {
      margin-bottom: 32px;
    }

    &__download-block {
      display: none;
    }
  }

  .text {
    &__head {
      margin-bottom: 22px;
    }

    &__normal {
      margin-bottom: 0;
    }
  }

  .office-block {
    &__text {
      margin-bottom: 32px;
      margin-right: 0;
    }

    &__buttons {
      bottom: -68px;
    }
  }

  .office-top {
    margin-bottom: 118px;
  }

  .office-bottom {
    .text__head {
      margin-bottom: 33px;
    }
  }

  .office-slider {
    top: 13px;

    &__wrapper {
      img {
        max-width: 349px;
      }
    }
  }
}
</style>
